import { faStar, faUsersLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import IconBox from "../UI/IconBox/IconBox";
import './NumericResultsDisplay.scss';
import Colors from '../../App/App.scss';

const NumericResultsDisplay = (props) => {
    return (
        <Fragment>
            {!props.isBulk && <IconBox className='feed-back-count-icon'>
                <FontAwesomeIcon icon={faUsersLine} size='1x' color={Colors.ceruleanCrayola} />
                <div className='feed-back-count std-text'>{props.fBCount}</div>
            </IconBox>}
            <IconBox className='avg-score-icon'>
                <FontAwesomeIcon icon={faStar} size='1x' color={Colors.yellowCrayola} />
                <div className='avg-score std-text'>{props.avgScore}</div>
            </IconBox>
        </Fragment>
    );
}

export default NumericResultsDisplay;