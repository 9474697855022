import expireDateHelper from '../helper/ExpireDateHelper';

export default class TestRequests {
    constructor() {
        //css test verisi
        const cssTest = [];
        cssTest.push({ name: "ilk - 123456789123456789123456789123456789123456789123456789", fBCount: 123456789, expireDate: new Date(2042, 0, 1, 20, 0, 0), id: -1, avgScore: 4.3 },);
        this.fillDummy(cssTest, 50);
        cssTest.push({ name: "son - 123456789123456789123456789123456789123456789123456789", fBCount: 123456789, expireDate: new Date(2042, 0, 1, 20, 0, 0), id: -1, avgScore: 4.3 },);
        this.cssTestData = this.fillCalculatedData(cssTest);
        //filtre test verisi
        const filterTest = [];
        filterTest.push(
            { name: "Expired request 1", fBCount: 1, expireDate: new Date(2021, 0, 1, 20, 0, 0), id: Math.random(), avgScore: 4.3 },
            { name: "Expired request 2", fBCount: 1, expireDate: new Date(2021, 0, 1, 20, 0, 0), id: Math.random(), avgScore: 4.3 },
            { name: "Active request 1", fBCount: 1, expireDate: new Date(2023, 0, 1, 20, 0, 0), id: Math.random(), avgScore: 4.3 },
            { name: "Active request 2", fBCount: 1, expireDate: new Date(2023, 0, 1, 20, 0, 0), id: Math.random(), avgScore: 4.3 },

        );
        this.filterTestData = this.fillCalculatedData(filterTest);
        //sorting test data
        const sortingTest = [];
        sortingTest.push(
            { name: "1", fBCount: 1, expireDate: new Date(2021, 0, 1, 20, 0, 0), id: Math.random(), avgScore: 4.3 },
            { name: "2", fBCount: 2, expireDate: new Date(2021, 0, 2, 20, 0, 0), id: Math.random(), avgScore: 3.3 },
            { name: "3", fBCount: 3, expireDate: new Date(2023, 0, 1, 20, 0, 0), id: Math.random(), avgScore: 2.3 },
            { name: "4", fBCount: 4, expireDate: new Date(2023, 0, 2, 20, 0, 0), id: Math.random(), avgScore: 1.3 },
        );
        this.sortingTestData = this.addSubjectAndDescription(this.fillCalculatedData(sortingTest));
        //search data test
        const searchTest = [];
        searchTest.push(
            { name: "abc abc abc", fBCount: 1, expireDate: new Date(2021, 0, 1, 20, 0, 0), id: Math.random(), avgScore: 4.3 },
            { name: "123 abc", fBCount: 2, expireDate: new Date(2021, 0, 2, 20, 0, 0), id: Math.random(), avgScore: 3.3 },
            { name: "a_b-c a!b-'c a.b,c \"abc\"", fBCount: 3, expireDate: new Date(2023, 0, 1, 20, 0, 0), id: Math.random(), avgScore: 2.3 },
            { name: " ", fBCount: 4, expireDate: new Date(2023, 0, 2, 20, 0, 0), id: Math.random(), avgScore: 1.3 },

        );
        this.searchTestData = this.fillCalculatedData(searchTest);
    }

    fillDummy(arr, count) {
        for (let i = 0; i < count; i++) {
            arr.push({ name: "dummy", id: Math.random(), fBCount: i, expireDate: new Date(2022, i % 12, (i + 1) % 31, 20, 0, 0), avgScore: (Math.random() * 5).toFixed(1) });
        }
    }

    fillCalculatedData(arr) {
        return arr.map(requestItem => {
            const additionalData = expireDateHelper(requestItem.expireDate);
            return { ...requestItem, isExpired: additionalData.isExpired, remainingTime: additionalData.remainingTime, dayHourStr: additionalData.dayHourStr };
        });
    }

    addSubjectAndDescription(arr) {
        return arr.map((requestItem, index) => {
            const subject1 = {
                id: index,
                subject: "test subject" + index,
                fBCount: 4.1,
                avgScore: 4.2,
            };
            const subject2 = {
                id: index + 1,
                subject: "test subject" + index,
                fBCount: 345556,
                avgScore: 4.2,
            };
            const description = "test descrition" + index;
            return { ...requestItem, subjects: [subject1, subject2], description: description };
        });
    }
}
