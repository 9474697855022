import './Login.scss';
import '../../App/App.scss';
import Button from '../UI/Button/Button';
import Link from '../UI/Link/Link';
import { useNavigate } from 'react-router-dom';
import { LINKS_INTERNAL } from '../../App/App';
import useValidation from '../../hooks/use-validation';
import ValidationErrorBox from '../UI/ValidationErrorBox/ValidationErrorBox';
import Card from '../UI/Card/Card';
import Colors from '../../App/App.scss';
import { useState } from 'react';
import useHttp from '../../hooks/use-http';
import { authActions } from '../../store/auth-slice';
import { useDispatch } from 'react-redux';
import Spinner from '../UI/Spinner/Spinner';

const Login = () => {
    const [isLogin, setIsLogin] = useState(true);
    const { isLoading, error, sendRequest } = useHttp();
    const dispatch = useDispatch();
    const {
        onBlurHandler: userNameBlurHandler,
        onChangeHandler: userNameChangeHandler,
        value: userNameValue,
        isInputValid: isUserNameValid,
        hasErrors: userNameHasErrors,
        onFocusHandler: userNameFocusHandler,
        showErrorBox: userNameShowErrorBox,
        forceError: userNameForceError
    } = useValidation((val) => {
        const formatCheck = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,16})+$/.test(val);
        return formatCheck;
    });

    const {
        onBlurHandler: passwordBlurHandler,
        onChangeHandler: passwordChangeHandler,
        value: passwordValue,
        isInputValid: isPasswordValid,
        hasErrors: passwordHasErrors,
        onFocusHandler: passwordFocusHandler,
        showErrorBox: passwordShowErrorBox,
        forceError: passwordForceError
    } = useValidation((val) => val.trim().length > 7);

    const formHasErrors = !isUserNameValid || !isPasswordValid;

    let navigate = useNavigate();

    const loginSucceeded = (data) => {
        const expirationTime = new Date(
            new Date().getTime() + data.expiresIn * 1000
        );
        dispatch(authActions.login({ token: data.idToken, uid: data.localId, expirationTime: expirationTime.toISOString() }));
        navigate(LINKS_INTERNAL.FEED_BACK_REQUESTS, { replace: false });
        return true;
    }

    function onSubmitHandler(event) {
        event.preventDefault();
        if (formHasErrors) {
            userNameForceError();
            passwordForceError();
            return;
        }
        let url;
        if (isLogin) {
            url =
                'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyDSqt201zH9i_QTJV38hRPrlAM3NM78IcA';
        } else {
            url =
                'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyDSqt201zH9i_QTJV38hRPrlAM3NM78IcA';
        }
        sendRequest(
            {
                url,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: {
                    email: userNameValue,
                    password: passwordValue,
                    returnSecureToken: true,
                },
            }, loginSucceeded);
    }

    function toggleLogIn() {
        setIsLogin((prevState) => !prevState);
    };

    return (
        <Card backgroundColor={Colors.magnolia}>
            <form id="login-box" className="std-text" style={{ userSelect: "none" }} onSubmit={onSubmitHandler}>
                {error && <p className='ghost-error-message std-text'>Invalid username or password</p>}
                <ValidationErrorBox message='Please enter a valid email' showErrorBox={userNameShowErrorBox}>
                    <input id="username" className={`text-input ${userNameHasErrors && 'invalid'}`} type="email" placeholder="e-mail" onFocus={userNameFocusHandler} onBlur={userNameBlurHandler} onChange={userNameChangeHandler} value={userNameValue} />
                </ValidationErrorBox>
                <ValidationErrorBox message='Please enter a password at least 8 characters long' showErrorBox={passwordShowErrorBox}>
                    <input id="password" className={`text-input ${passwordHasErrors && 'invalid'}`} type="password" placeholder="password" onFocus={passwordFocusHandler} onBlur={passwordBlurHandler} onChange={passwordChangeHandler} value={passwordValue} />
                </ValidationErrorBox>
                {!isLoading && <Button btnType="primary-button login-button" type="submit">{isLogin ? "Login" : "Create Account"}</Button>}
                {isLoading && <Spinner margin='2px 0px 1px 0px' />}
                <Link linkClicked={toggleLogIn} style={{ padding: "10px" }}>{isLogin ? 'Create new account' : 'Login with existing account'}</Link>
            </form>
        </Card >
    );
}

export default Login;
