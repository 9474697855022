import { useContext, useEffect } from "react";
import WisardLayout from './WisardLayout';
import AddRequestContext from "./add-request-context";
import useValidation from "../../hooks/use-validation";
import ValidationErrorBox from "../UI/ValidationErrorBox/ValidationErrorBox";

const AddRequestWisardExpireDate = () => {

    const getLocalDateTime = (dayOffset) => {
        const now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset()); // setting local time
        now.setDate(now.getDate() + dayOffset);
        return now.toISOString().slice(0, 16);;
    }

    const validateDateFunc = (val) => {
        const isNonEmptyString = val !== '';
        const isGreaterThanNow = val > getLocalDateTime(0);
        const isSmallerThanOneYearLater = val < getLocalDateTime(365);
        return isNonEmptyString && isGreaterThanNow && isSmallerThanOneYearLater;
    }

    const {
        onBlurHandler: dateBlurHandler,
        onChangeHandler: dateChangeHandler,
        value: dateValue,
        isInputValid: isDateValid,
        hasErrors: dateHasErrors,
        onFocusHandler: dateFocusHandler,
        showErrorBox: dateShowErrorBox,
        forceError: dateForceError,
        setValue: setDateValue
    } = useValidation(validateDateFunc);

    const ctx = useContext(AddRequestContext);
    const desc = 'Please pick a date for the expiration of your feedback request.';
    const moreDesc = `\nExpire date is recommended to be no more than 1 month. By deafult it is set to 1 week`;

    const forceValidate = () => {
        if (!isDateValid) {
            dateForceError();
        }
        return isDateValid;
    }

    const saveState = () => {
        ctx.setExpireDate(dateValue);
    }

    useEffect(() => {
        let dateTimeVal = getLocalDateTime(7);
        setDateValue(dateTimeVal); //The format is "yyyy-MM-ddThh:mm" followed by optional ":ss" or ":ss.SSS". e.g. now: 2022-04-27T01:53:15
    }, []);

    const minDateTime = getLocalDateTime(0);
    const maxDateTime = getLocalDateTime(365); // setting date 1 year later than now

    return (
        <WisardLayout context={AddRequestContext} description={desc} moreDescription={moreDesc} nextButtonName='Continue' backButtonName='Back' forceValidate={forceValidate} saveState={saveState}>
            <ValidationErrorBox message='Please enter a valid date between now and one year from now.' showErrorBox={dateShowErrorBox}>
                <input type="datetime-local" className={`text-input date-input ${dateHasErrors && 'invalid'}`} onFocus={dateFocusHandler} onBlur={dateBlurHandler} onChange={dateChangeHandler} value={dateValue} min={minDateTime} max={maxDateTime} autoFocus />
            </ValidationErrorBox>
        </WisardLayout>
    );
}
export default AddRequestWisardExpireDate;