import FullPageSection from '../../UI/FullPageSection/FullPageSection';
import './SelfFeedBackSection.scss'
import Link from '../../UI/Link/Link';
import Colors from '../../../App/App.scss'
import QrCode from '../../UI/QrCode/QrCode';

function SelfFeedBackSection() {
    const address = 'https://feedbackclub.app/givefeedback/nqkmpfzvGUeZIv8gv0xy7PvsAj82/-NB85epEXTl3m1if4soY';
    function feedbackLink() {
        window.open(
            address,
            '_blank'
        );
    }

    return (
        <FullPageSection style={{ color: Colors.fbcBlue, backgroundColor: Colors.beige, flexDirection: 'column', alignItems: 'center', padding: '50px' }}>
            <div className='std-text feedback-section-text'>We need your feed back to improve Feedback Club! Please share your thoughts by clicking <a href={address} className="std-text">here</a>.</div>
            <div className='std-text feedback-section-text'>The QR code is an alternative to the link above. This is how feedbackclub works. When you create your own feedback request, a QR code and a link will be provided to you for sharing.</div>
            <QrCode link={address} />
        </FullPageSection>
    );
}

export default SelfFeedBackSection;