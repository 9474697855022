import { useEffect, useReducer, useState } from "react";
import AddRequestContext from "./add-request-context";
import AddRequestWisardIntro from './AddRequestWisardIntro';
import AddRequestWisardTopic from "./AddRequestWisardTopic";
import AddRequestWisardDescription from "./AddRequestWisardDescription";
import AddRequestWisardSubjects from "./AddRequestWisardSubjects";
import { LINKS_INTERNAL } from '../../App/App';
import { useLocation, useNavigate } from "react-router-dom";
import AddRequestWisardExpireDate from "./AddRequestWisardExpireDate";
import AddRequestPreview from "./AddRequestPreview";
import AddRequestWisardSharePage from "./AddRequestWisardSharePage";
import AddRequestWisardBulkCreate from "./AddRequestWisardBulkCreate";

const initialState = { step: 1 }
const stepCount = 8;

const reduce = (state, action) => {
    if (action.type === 'forward') {
        return {
            step: state.step + 1
        }
    } else if (action.type === 'backward') {
        return {
            step: state.step - 1
        }
    } else if (action.type === 'cancel') {
        return {
            step: -1
        }
    } else {
        throw new Error();
    }
}

const AddRequestWisard = () => {
    const [state, dispatch] = useReducer(reduce, initialState);
    const [topic, setTopic] = useState();
    const [description, setDescription] = useState();
    const [subjects, setSubjects] = useState();
    const [expireDate, setExpireDate] = useState();
    const [referenceKey, setReferenceKey] = useState();
    const [requestCount, setRequestCount] = useState();
    const [isMovingForward, setIsMovingForward] = useState();
    const navigate = useNavigate();

    const location = useLocation();
    const isbulk = location.pathname.includes('bulk');
    useEffect(() => {
        if (state.step < 1) {
            navigate(LINKS_INTERNAL.FEED_BACK_REQUESTS, { replace: false });
        }
        if (state.step > stepCount) {
            navigate(LINKS_INTERNAL.FEED_BACK_REQUESTS, { replace: false });
        }
    }, [navigate, state.step]);

    const forward = () => {
        setIsMovingForward(true);
        dispatch({
            type: 'forward',
        });
    }

    const backward = () => {
        setIsMovingForward(false);
        dispatch({
            type: 'backward',
        });
    }

    const cancel = () => {
        dispatch({
            type: 'cancel',
        });
    }

    if (state.step === 6 && !isbulk) {
        if (isMovingForward) {
            forward();
        } else {
            backward();
        }
    }

    return (
        <AddRequestContext.Provider value={{
            topic,
            description,
            subjects,
            expireDate,
            referenceKey,
            requestCount,
            setTopic,
            setDescription,
            setSubjects,
            setExpireDate,
            setRequestCount,
            setReferenceKey,
            moveForward: forward,
            moveBackward: backward,
            cancel
        }} >
            {(state.step === 1) && <AddRequestWisardIntro></AddRequestWisardIntro>}
            {(state.step === 2) && <AddRequestWisardTopic></AddRequestWisardTopic>}
            {(state.step === 3) && <AddRequestWisardDescription></AddRequestWisardDescription>}
            {(state.step === 4) && <AddRequestWisardSubjects></AddRequestWisardSubjects>}
            {(state.step === 5) && <AddRequestWisardExpireDate></AddRequestWisardExpireDate>}
            {(state.step === 6) && <AddRequestWisardBulkCreate></AddRequestWisardBulkCreate>}
            {(state.step === 7) && <AddRequestPreview></AddRequestPreview>}
            {(state.step === 8) && <AddRequestWisardSharePage></AddRequestWisardSharePage>}
        </AddRequestContext.Provider >
    );
}

export default AddRequestWisard;