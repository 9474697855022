const expireDateHelper = (expireDate) => {
    const now = new Date();
    let expired = false;
    if (now >= expireDate) {
        expired = true;
    }

    let diff = 0;
    let remainingTime = 0;
    let dayHourStr = '';
    if (!expired) {
        diff = expireDate - now;
        const hourMs = 1000 * 60 * 60;
        const dayMs = hourMs * 24;
        if (diff > dayMs) {
            remainingTime = Math.floor(diff / dayMs);
            dayHourStr = 'Day' + (remainingTime > 1 ? 's' : '');
        } else {
            remainingTime = Math.floor(diff / hourMs);
            dayHourStr = 'Hour' + (remainingTime > 1 ? 's' : '');
        }
    }

    return {
        isExpired: expired,
        remainingTime,
        dayHourStr
    }
}

export default expireDateHelper;