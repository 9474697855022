import { useSelector } from 'react-redux';
import FullPageSection from '../../UI/FullPageSection/FullPageSection';
import './InfoSection.scss'

function InfoSection() {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  return (
    <FullPageSection>
      {isLoggedIn && <div className="space" />}
      <div className="std-text info-section-description">Feed your growth and learning mind set with feedbacks.</div>
      <div className="std-text info-section-description">Feedback club is the most effective and easiest way of getting feedback after any occasion.</div>
      <div className="std-text info-section-description">Create a feedback request and share the QR code or link with your community.</div>
      <div className="std-text info-section-warning-description">Warning! This is the beta test release of feedback club. You can sign-up, login, create feedback requests and get feedback. However your data may be deleted at any time without warning.</div>
      {isLoggedIn && <div className="space" />}
    </FullPageSection>
  );
}

export default InfoSection;
