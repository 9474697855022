import { useNavigate } from 'react-router-dom';
import { LINKS_INTERNAL } from '../../../App/App';
import './Logo.scss'

function Logo(props) {
    const navigate = useNavigate();
    const onClickHandler = () => {
        navigate(LINKS_INTERNAL.MAIN_PAGE, { replace: false });
    }
    return (
        <div id="logo" onClick={onClickHandler} style={{ 'fontSize': props.fontSize + 'px', color: props.color }}>FEED<br />BACK<br />CLUB</div>
    );
}
export default Logo;