import CalenderDayView from '../CalenderDayView/CalenderDayView';
import RemainingTime from '../RemainingTime/RemainingTime';
import './ExpireDate.scss';

function ExpireDate(props) {
    return (
        <div className='expire-date'>
            <CalenderDayView date={props.expireDate} expired={props.expired} />
            <RemainingTime expireDate={props.expireDate} expired={props.expired} remainingTime={props.remainingTime} dayHourStr={props.dayHourStr} />
        </div>
    );
}
export default ExpireDate;