import WisardLayout from "../AddRequestWisard/WisardLayout";
import AddRequestContext from "../AddRequestWisard/add-request-context";
import Button from "../UI/Button/Button";
import { useContext } from "react";
import ShareRequestPage from "../ShareRequestPage/ShareRequestPage";
import { useSelector } from "react-redux";

const AddRequestWisardSharePage = () => {
    const ctx = useContext(AddRequestContext);
    const desc = 'Congratulations!';
    const bulkDesc = 'Congratulations! feedback-provider.json file has been downloaded.'
    const bulkMoreDescription = 'Place feedback-provider.json file to public/feedback-provider folder of your react.js project. Run "npm install --save feedback-provider" and start using <feedback-provider /> component on your project. Dont forget to give a "sequenceNumber" each time you embed a component to your DOM tree.'
    const moreDescription = 'Share your feedback request with people. You can open this "share feedback" dialog later by clicking on the QR code icon on your feedback request.'
    const uid = useSelector(state => state.auth.uid);
    const onNextClickHandler = () => {
        ctx.moveForward();
    }
    const bulkWisardPage =
        <WisardLayout context={AddRequestContext} description={bulkDesc} moreDescription={bulkMoreDescription} forceValidate={() => { return true; }} saveState={() => { }} isShowButtons={false}>
            <Button btnType='primary-button' onClick={onNextClickHandler}>Go to Feedback Requests</Button>
            <div style={{ marginBottom: "40px" }}></div>
        </WisardLayout>
    const wisardPage =
        <WisardLayout context={AddRequestContext} description={desc} moreDescription={moreDescription} forceValidate={() => { return true; }} saveState={() => { }} isShowButtons={false}>
            <ShareRequestPage requestReferenceKey={ctx.referenceKey} uid={uid} />
            <Button btnType='primary-button' onClick={onNextClickHandler}>Go to Feedback Requests</Button>
            <div style={{ marginBottom: "40px" }}></div>
        </WisardLayout>
    return ctx.requestCount ? bulkWisardPage : wisardPage;
}
export default AddRequestWisardSharePage;