import './SearchBar.scss'
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faX } from '@fortawesome/free-solid-svg-icons';

function SearchBar(props) {
    const [searchKeys, setSearchKeys] = useState('');
    useEffect(() => {
        if (props.reset) {
            setSearchKeys('');
        }
    }, [props.reset]);

    const onChangeHandler = (event) => {
        setSearchKeys(event.target.value);
        props.onSubmit(event.target.value);
    }

    const cancelHandler = () => {
        setSearchKeys('');
        props.onSubmit('');
    }

    return <div className='search-bar'>
        <FontAwesomeIcon className="search-icon" icon={faMagnifyingGlass} size='1x' color='white' />
        <input type="text" className="search-field std-text" placeholder="Search" onChange={onChangeHandler} value={searchKeys} />
        <FontAwesomeIcon className="cancel-icon" icon={faX} size='1x' color='white' visibility={searchKeys ? 'visible' : 'hidden'} onClick={cancelHandler} />
    </div>


}

export default SearchBar;