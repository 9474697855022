import useValidation from "../../hooks/use-validation";
import ValidationErrorBox from "../UI/ValidationErrorBox/ValidationErrorBox";
import WisardLayout from "./WisardLayout";
import './AddRequestWisardTopic.scss';
import AddRequestContext from "./add-request-context";
import { useContext, useEffect } from "react";

const AddRequestWisardTopic = () => {
    const {
        onBlurHandler: topicBlurHandler,
        onChangeHandler: topicChangeHandler,
        value: topicValue,
        isInputValid: isTopicValid,
        hasErrors: topicHasErrors,
        onFocusHandler: topicFocusHandler,
        showErrorBox: topicShowErrorBox,
        forceError: topicForceError,
        setValue: setTopicValue
    } = useValidation((val) => {
        const len = val.trim().length;
        return len > 2 && len < 512;
    });

    const ctx = useContext(AddRequestContext);

    //loading input values from context if saved earlier (e.g. if his page is loaded with back button)
    useEffect(() => {
        if (ctx.topic) {
            setTopicValue(ctx.topic);
        }
    }, [ctx.topic, setTopicValue]);

    const forceValidate = () => {
        if (!isTopicValid) {
            topicForceError();
        }
        return isTopicValid;
    }

    const desc = 'Please type a topic for your feedback request.';
    const moreDesc = `\nIt is recommended that you include your name and a brief description of the situation for which you are requesting feedback. \n\n Some examples:\n\n "Erdem ERGUL's presentation performance on May 21, 2022", \n\n"Matt Gruger's work performance in June 2022"`;
    const validationErrorMes = 'Please enter a topic between 3-512 characters long';
    const saveState = () => {
        ctx.setTopic(topicValue);
    }

    return (
        <WisardLayout context={AddRequestContext} description={desc} moreDescription={moreDesc} nextButtonName='Continue' backButtonName='Back' forceValidate={forceValidate} saveState={saveState}>
            <ValidationErrorBox message={validationErrorMes} showErrorBox={topicShowErrorBox}>
                <input className={`text-input topic-input ${topicHasErrors && 'invalid'}`} type="text" placeholder="feedback topic" onFocus={topicFocusHandler} onBlur={topicBlurHandler} onChange={topicChangeHandler} value={topicValue} autoFocus />
            </ValidationErrorBox>
        </WisardLayout>
    );
}

export default AddRequestWisardTopic;