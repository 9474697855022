import './Backdrop.scss';
import { modalActions } from '../../../store/show-modal-slice';
import { useDispatch } from 'react-redux';

const Backdrop = () => {

    const dispatch = useDispatch();

    const onClickHandler = () => {
        dispatch(modalActions.hide());
    }

    return (
        <div className="backdrop" onClick={onClickHandler} />
    );
}

export default Backdrop;