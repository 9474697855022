import './App.scss';
import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';
import MainPage from '../Components/MainPage/MainPage';
import FeedBackRequests from '../Components/FeedBackRequests/FeedBackRequests.js';
import AddRequestWisard from '../Components/AddRequestWisard/AddRequestWisard';
import GiveFeedBackPage from '../Components/GiveFeedBackPage/GiveFeedBackPage';
import TestRequests from '../TestData/TestRequests';
import { useSelector } from 'react-redux';
import useAutoLogoutBeforeExpire from '../hooks/use-autoLogoutBeforeExpire.js';
import PrivacyPolicy from '../Components/PrivacyPolicy/PrivacyPolicy';
import AboutPage from '../Components/AboutPage/AboutPage';

export const LINKS_INTERNAL =
{
  FEED_BACK_REQUESTS: "/feedbackrequests",
  MAIN_PAGE: "/",
  ADD_REQUEST_WISARD: "/addrequestwizard",
  ADD_REQUEST_WISARD_BULK: "/addrequestwizard/bulk",
  GIVE_FEED_BACK: "/givefeedback/:uid/:requestId",
  GIVE_FEED_BACK_BULK: "/givefeedback/:uid/:requestId/:sequenceId",
  PRIVACY_POLICY: "/privacy",
  ABOUT_PAGE: "/about"
}

function App() {
  useAutoLogoutBeforeExpire();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const testData = new TestRequests();
  const feedBackRequests = testData.sortingTestData;
  const feedbackrequest = feedBackRequests[0];
  const giveFeedBackPage = <GiveFeedBackPage
    topic={feedbackrequest.name}
    description={feedbackrequest.description}
    subjects={feedbackrequest.subjects}
    expireDate={feedbackrequest.expireDate}
    isExpired={false}
  />
  return (
    <React.Fragment>
      <Routes>
        {isLoggedIn && <Route path={LINKS_INTERNAL.FEED_BACK_REQUESTS} element={<FeedBackRequests />} />}
        <Route path={LINKS_INTERNAL.MAIN_PAGE} element={<MainPage />} />
        {isLoggedIn && <Route path={LINKS_INTERNAL.ADD_REQUEST_WISARD} element={<AddRequestWisard />} />}
        {isLoggedIn && <Route path={LINKS_INTERNAL.ADD_REQUEST_WISARD_BULK} element={<AddRequestWisard />} />}
        <Route path={LINKS_INTERNAL.GIVE_FEED_BACK} element={giveFeedBackPage} />
        <Route path={LINKS_INTERNAL.GIVE_FEED_BACK_BULK} element={giveFeedBackPage} />
        <Route path={LINKS_INTERNAL.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={LINKS_INTERNAL.ABOUT_PAGE} element={<AboutPage />} />
        <Route path='*' element={<Navigate replace to={LINKS_INTERNAL.MAIN_PAGE} />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
