import Header from "../Header/Header";
import Link from "../Link/Link";
import Logo from '../Logo/Logo';
import Colors from '../../../App/App.scss';
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/auth-slice";

const PageHeader = (props) => {
    const dispatch = useDispatch();
    const linkClickedHandler = () => {
        dispatch(authActions.logout());
    }

    return (
        <Header className={props.className}>
            <Logo fontSize={30} color={Colors.lavenderGray}></Logo>
            <div className="neon-text">Get feedback, get stronger!</div>
            {props.children}
            <Link style={{ color: Colors.lavenderGray, fontSize: 'large' }} linkClicked={linkClickedHandler}>Logout</Link>
        </Header>
    );
}
export default PageHeader;