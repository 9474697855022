import { useState, useCallback } from 'react';

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  let parentWillUnmount = false;

  const sendRequest = useCallback(async (requestConfig, applyData) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(requestConfig.url, {
        method: requestConfig.method ? requestConfig.method : 'GET',
        headers: requestConfig.headers ? requestConfig.headers : {},
        body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
      });

      if (!response.ok) {
        throw new Error('Request failed! ' + response.status + " " + response.statusText);
      }

      const data = await response.json();
      parentWillUnmount = applyData(data);
    } catch (err) {
      setError(err.message || 'Something went wrong!');
    }

    // When response lead to unmount in the component using this hook, this state change leads to following warning:
    // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
    if (!parentWillUnmount) {
      setIsLoading(false);
    }

  }, []);

  return {
    isLoading,
    error,
    sendRequest,
  };
};

export default useHttp;
