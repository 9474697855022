import Link from '../Link/Link';
import Footer from "../Footer/Footer";
import { useNavigate } from 'react-router-dom';
import { LINKS_INTERNAL } from '../../../App/App';

const PageFooter = () => {

    const navigate = useNavigate();

    function feedbackLink() {
        navigate(LINKS_INTERNAL.PRIVACY_POLICY, { replace: false });
    }

    function aboutLink() {
        navigate(LINKS_INTERNAL.ABOUT_PAGE, { replace: false });
    }

    return (
        <Footer>
            <Link linkClicked={feedbackLink} style={{ color: 'white' }}>Privacy Policy</Link>
            <Link linkClicked={aboutLink} style={{ color: 'white', paddingRight: "5px" }}>About Feedback Club</Link>
        </Footer>
    );
}
export default PageFooter;