import RequestItem from './RequestItem';
import './RequestList.scss';

function RequestList(props) {
    return (
        <ul className='request-list'>{
            props.listItems.map((f) => {
                return <RequestItem item={f} key={f.id} setRequestList={props.setRequestList} />;
            })
        }</ul>);
}
export default RequestList;