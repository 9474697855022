import './VotingButtons.scss';
import VotingButton from "./Votingbutton";
import { useState } from 'react';

const VotingButtons = (props) => {

    const [val, setVal] = useState();
    const [prevVal, setPrevVal] = useState();

    const onMouseEnteredHandler = (id) => {
        setVal(id);
    }

    const onMouseLeavedHandler = () => {
        setVal(prevVal);
    }

    const onClickHandler = (id) => {
        setPrevVal(id);
        setVal(id);
        props.onValueChanged(id);
    }

    const notSelectedColor = 'white';
    const selectedColor = 'yellow';

    let color1 = notSelectedColor;
    let color2 = notSelectedColor;
    let color3 = notSelectedColor;
    let color4 = notSelectedColor;
    let color5 = notSelectedColor;

    const setColors = (id) => {
        color1 = id > 0 ? selectedColor : notSelectedColor;
        color2 = id > 1 ? selectedColor : notSelectedColor;
        color3 = id > 2 ? selectedColor : notSelectedColor;
        color4 = id > 3 ? selectedColor : notSelectedColor;
        color5 = id > 4 ? selectedColor : notSelectedColor;
    }

    setColors(val);

    return (
        <div className="star-buttons-container">
            <VotingButton id={1} onMouseEntered={onMouseEnteredHandler} onMouseLeaved={onMouseLeavedHandler} onClick={onClickHandler} color={color1} />
            <VotingButton id={2} onMouseEntered={onMouseEnteredHandler} onMouseLeaved={onMouseLeavedHandler} onClick={onClickHandler} color={color2} />
            <VotingButton id={3} onMouseEntered={onMouseEnteredHandler} onMouseLeaved={onMouseLeavedHandler} onClick={onClickHandler} color={color3} />
            <VotingButton id={4} onMouseEntered={onMouseEnteredHandler} onMouseLeaved={onMouseLeavedHandler} onClick={onClickHandler} color={color4} />
            <VotingButton id={5} onMouseEntered={onMouseEnteredHandler} onMouseLeaved={onMouseLeavedHandler} onClick={onClickHandler} color={color5} />
        </div>
    );
}

export default VotingButtons;