import './ValidationErrorBox.scss'

const ValidationErrorBox = (props) => {
    return (
        <div className='validity-container'>
            {props.showErrorBox && <div className="validation-error">{props.message}</div>}
            {props.children}
        </div>
    );
}

export default ValidationErrorBox;