import SelfFeedBackSection from './SelfFeedBackSection/SelfFeedBackSection';
import LoginSection from './LoginSection/LoginSection'
import InfoSection from './InfoSection/InfoSection'
import React, { Fragment } from 'react';
import PageFooter from '../UI/PageFooter/PageFooter';
import { useSelector } from 'react-redux';
import PageHeader from '../UI/PageHeader/PageHeader';
import './MainPage.scss';
import Button from '../UI/Button/Button';
import { useNavigate } from "react-router-dom";
import { LINKS_INTERNAL } from '../../App/App';
import BulkCreateSection from './BulkCreateSection/BulkCreateSection';

function MainPage() {
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const navigate = useNavigate();
    const onNextClickHandler = () => {
        navigate(LINKS_INTERNAL.FEED_BACK_REQUESTS, { replace: false });
    }
    return (
        <Fragment>
            {isLoggedIn &&
                <div className='main-page-container'>
                    <PageHeader />
                    <Button btnType='primary-button go-to-my-requests-btn' onClick={onNextClickHandler}>Go to Feedback Requests</Button>
                </div>
            }
            {!isLoggedIn && <LoginSection />}
            <InfoSection />
            <SelfFeedBackSection />
            <BulkCreateSection />
            <PageFooter />
        </Fragment>
    );
}
export default MainPage;