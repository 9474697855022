import { createSlice } from '@reduxjs/toolkit';

const initialModalState = {
    isShowModal: false,
    isShowRequestDetail: false,
    isShowShareRequestPage: false,
    itemIdToShowDetailOrQr: -1,
    isRefreshList: true
};

const modalSlice = createSlice({
    name: 'showModal',
    initialState: initialModalState,
    reducers: {
        showRequestDetail(state, action) {
            state.isShowModal = true;
            state.isShowRequestDetail = true;
            state.itemIdToShowDetailOrQr = action.payload;
            state.isShowShareRequestPage = false;
        },
        showShareRequest(state, action) {
            state.isShowModal = true;
            state.isShowRequestDetail = false;
            state.isShowShareRequestPage = true;
            state.itemIdToShowDetailOrQr = action.payload;
        },
        hide(state) {
            state.isShowModal = false;
            state.isShowRequestDetail = false;
            state.isShowShareRequestPage = false;
        },
        refreshList(state) {
            state.isRefreshList = !state.isRefreshList;
        }

    },
});

export const modalActions = modalSlice.actions;

export default modalSlice.reducer;