import './FeedBackForm.scss';
import SubjectListInput from './SubjectListInput';
import SubjectListDisplay from './SubjectListDisplay';

const FeedBackForm = (props) => {
    const { topic, description, subjects, expireDate, isExpired, isReadOnly, bulkrequestCount } = props;
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    let displayDate;
    if (isExpired)
        displayDate = "Expired";
    else {
        displayDate = "This feedback request will expire on: " + new Date(expireDate).toLocaleDateString("en-US", options);
    }

    return (
        <div className='feedbackform' style={props.style}>
            <div className={`feedbackform-topic std-text ${isExpired && "expired"}`}>
                {topic}
            </div>
            <div className="feedbackform-description std-text">
                {description}
            </div>
            {!isReadOnly && <SubjectListInput subjects={subjects} onValueChanged={props.onValueChanged} />}
            {isReadOnly && <SubjectListDisplay subjects={subjects} isBulk={bulkrequestCount} />}
            <div className={`feedbackform-expire-date std-text ${isExpired && "expired"}`}>
                {displayDate}
            </div>
        </div >
    );
}

export default FeedBackForm;