import NumericResultsDisplay from "../FeedBackRequests/NumericResultsDisplay";
import './SubjectListDisplay.scss';

const SubjectListDisplay = (props) => {
    const subjectsRendered = props.subjects.map((subject, index) => {
        // Çekirdek ekibinin talebi üzerine eklendi.
        console.log(subject.subject);
        console.log(subject.subjectScoreList);
        return (
            <div key={subject.id} className="subject-list-item">
                <div className="feedbackform-subject-name std-text">
                    {index + 1}. {subject.subject}
                </div>
                <div className="feedbackform-subject-numeric-results">
                    <NumericResultsDisplay fBCount={subject.fBCount} avgScore={subject.avgScore} isBulk={props.isBulk} />
                </div>
            </div >
        );
    }
    );
    return subjectsRendered;
}

export default SubjectListDisplay;