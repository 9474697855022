import FullPageSection from '../../UI/FullPageSection/FullPageSection';
import LoginBox from '../../LoginBox/Login'
import Logo from '../../UI/Logo/Logo';
import './LoginSection.scss'
import variables from "../../../App/App.scss";

function LoginSection() {
    return (
        <FullPageSection style={{ color: variables.darkCharcoal, backgroundColor: variables.lavenderGray }}>
            <Logo fontSize={100} />
            <div id="slogan" className="std-text">
                Get feedback get stronger!
            </div>
            <div id="login-section-description" className="std-text">Sign-up and start getting feedback now. It is free and very easy.</div>
            <LoginBox />
        </FullPageSection>
    );
}

export default LoginSection;
