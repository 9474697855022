import Button from "../Button/Button";
import Link from "../Link/Link";
import './LinkAndCopy.scss';

const LinkAndCopy = (props) => {

    const onClickHandler = () => {
        navigator.clipboard.writeText(props.address);
    }

    const linkClickHandler = () => {
        window.open(props.address, '_blank')
    }

    return (
        <div className='link-copy-container' style={{ width: props.width }}>
            <Link linkClicked={linkClickHandler} style={{ color: 'white', fontSize: 'larger', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{props.address}</Link>
            <Button btnType='secondary-button' onClick={onClickHandler}>Copy</Button>
        </div >
    );
}
export default LinkAndCopy;