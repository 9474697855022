import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import useValidation from "../../../hooks/use-validation";
import Button from "../Button/Button";
import ValidationErrorBox from "../ValidationErrorBox/ValidationErrorBox";
import './DynamicListItem.scss';

const DynamicListItem = (props) => {
    const { index, initialValue, id, validateFunction, validationErrorMessage, placeholder, forceValidate, valueChangedHandler } = props;

    const {
        onBlurHandler: inputBlurHandler,
        onChangeHandler: inputChangeHandler,
        value: inputValue,
        hasErrors: inputHasErrors,
        onFocusHandler: inputFocusHandler,
        showErrorBox: inputShowErrorBox,
        forceError: inputForceError,
        setValue: setInputValue
    } = useValidation(validateFunction);

    //On the first load if some value is stored in context it will be received via the props
    useEffect(() => {
        if (initialValue) {
            setInputValue(initialValue)
        }
    }, [initialValue, setInputValue]);

    useEffect(() => {
        valueChangedHandler(inputValue, id)
    }, [inputValue]);

    useEffect(() => {
        if (forceValidate) {
            inputForceError();
        }
    }, [forceValidate, inputForceError]);

    const onClickHandler = () => {
        props.onRemoveItem(id);
    }

    return (
        <ValidationErrorBox message={validationErrorMessage} showErrorBox={inputShowErrorBox}>
            <div className="dynamic-list-item">
                <div className="index-text">{index}</div>
                <input className={`text-input ${inputHasErrors && 'invalid'} ${props.inputClassName}`} type="text" placeholder={placeholder} onFocus={inputFocusHandler} onBlur={inputBlurHandler} onChange={inputChangeHandler} value={inputValue} autoFocus />
                <Button btnType='tertiary-btn item-delete-btn' onClick={onClickHandler}><FontAwesomeIcon icon={faXmark} size='2x' color={'white'} /></Button>
            </div>
        </ValidationErrorBox>
    );
}
export default DynamicListItem;