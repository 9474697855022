import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CSSTransition from 'react-transition-group/CSSTransition';
import { modalActions } from '../../../store/show-modal-slice';
import Backdrop from './Backdrop';
import './Modal.scss';
import Button from '../Button/Button'
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Modal = (props) => {
    const show = useSelector(state => state.modal.isShowModal);
    const dispatch = useDispatch();
    const modalOnClickHandler = () => {
        dispatch(modalActions.hide());
    }

    return (
        <Fragment>
            <CSSTransition in={show} classNames='modal-transition' timeout={500} mountOnEnter unmountOnExit>
                <div className='modal-container card'>
                    <Button btnType="tertiary-button modal-close-btn" onClick={modalOnClickHandler}><FontAwesomeIcon icon={faXmark} size='2x' color={'white'} /></Button>
                    {props.children}
                </div>
            </CSSTransition>
            <CSSTransition in={show} classNames='backdop-transition' timeout={500} mountOnEnter unmountOnExit>
                <Backdrop />
            </CSSTransition>
        </Fragment>
    );
}

export default Modal;