import { useEffect, useState } from 'react';
import './SelectionBox.scss'

function SelectionBox(props) {

  const [selection, setSelection] = useState();

  useEffect(() => {
    if (props.reset) {
      setSelection('All');
    }
  }, [props.reset]);

  const onChangeHandler = (event) => {
    props.onChange(event.target.value);
    setSelection(event.target.value);
  }


  return <select value={selection} className={`selection-box std-text ${props.className}`} onChange={onChangeHandler}>
    {props.values.map((val, i) => <option value={val} key={i}>{val}</option>)}
  </select>
}

export default SelectionBox;