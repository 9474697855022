import { Fragment, useContext } from "react";
import FeedBackForm from "../FeedBackForm/FeedBackForm";
import AddRequestContext from "./add-request-context";
import WisardLayout from "./WisardLayout";
import './AddrequestPreview.scss';
import useHttp from "../../hooks/use-http";
import Spinner from "../UI/Spinner/Spinner";
import ErrorParagraph from "../UI/ErrorParagraph/ErrorParagraph";
import { useSelector } from "react-redux";
import downloadJsonAsFile from "../../misc/FileDownloader";

const AddRequestPreview = () => {
    const ctx = useContext(AddRequestContext);
    const dsc = "This is the preview of your feedback request.";
    const moreDsc = "You can click on finish button to start sharing your feedback request with people. Or you can go back and change the content. After finishing the wizard you can not edit this feedback request.";

    const { isLoading, error, sendRequest: sendPOSTRequest } = useHttp();
    const uid = useSelector(state => state.auth.uid);
    const token = useSelector(state => state.auth.token);

    const feedbackRequestObject = {
        topic: ctx.topic,
        description: ctx.description,
        subjects: ctx.subjects,
        expireDate: ctx.expireDate,
    }

    // Todo: bulkRequestObject içinde subjects'e gerek yok gibi duruyor. Çıkartmayı değerlendir.
    const bulkRequestObject = {
        ...feedbackRequestObject,
        bulkRequestCount: ctx.requestCount,
        relatedBulkRequestList: []
    };

    const persistRequest = async () => {
        if (ctx.requestCount) {
            for (let i = 0; i < ctx.requestCount; i++) {
                bulkRequestObject.relatedBulkRequestList.push(cloneRequest(feedbackRequestObject, i));
            }
            createNewRequest(bulkRequestObject, downloadConfigurationFileAndGoToNextPage);
        } else {
            createNewRequest(feedbackRequestObject, goToNextPage);
        }
    }

    const cloneRequest = (feedbackRequestObject, index) => {
        const feedbackRequestObjectClone = JSON.parse(JSON.stringify(feedbackRequestObject));
        feedbackRequestObjectClone.topic = index + '. ' + feedbackRequestObjectClone.topic;
        feedbackRequestObjectClone.subjects.forEach(subject => {
            subject.parentSubjectId = subject.id;
            subject.id = crypto.randomUUID();
        });
        feedbackRequestObjectClone.isChildRequest = true;
        return feedbackRequestObjectClone;
    }

    const downloadConfigurationFile = (requestReferenceKey) => {
        const contents = {
            "userId": uid,
            "requestReferenceKey": requestReferenceKey,
            "requestCount": ctx.requestCount
        }
        downloadJsonAsFile(contents, 'feedback-provider');
    };

    const createNewRequest = (bodyData, callBack) => {
        sendPOSTRequest(
            {
                url: `https://feedback-club-v1-default-rtdb.europe-west1.firebasedatabase.app/feedbackRequest/${uid}.json?auth=${token}`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: bodyData,
            },
            callBack
        );
    }

    const goToNextPage = (data) => {
        ctx.setReferenceKey(data.name);
        ctx.moveForward();
        return true;
    }

    const downloadConfigurationFileAndGoToNextPage = (data) => {
        downloadConfigurationFile(data.name);
        goToNextPage(data);
    }

    return (
        <Fragment>
            <WisardLayout context={AddRequestContext} saveState={persistRequest} forceValidate={() => { return true; }} description={dsc} moreDescription={moreDsc} backButtonName="Back" nextButtonName="Finish" hideCancelButton={false} isMoveForwardDisabled={true} isSaveOnBackDisabled={true}>
                {error && <ErrorParagraph>{error}</ErrorParagraph>}
                {isLoading && <Spinner />}
                {!isLoading && !error && <div className="feedbackform-container">
                    <FeedBackForm topic={ctx.topic} description={ctx.description} subjects={ctx.subjects} expireDate={ctx.expireDate} isReadOnly={false} style={{ paddingTop: "30px" }} />
                </div>}
            </WisardLayout>
        </Fragment>
    );
}
export default AddRequestPreview;