import { useSelector } from 'react-redux';
import FullPageSection from '../../UI/FullPageSection/FullPageSection';
import './BulkCreateSection.scss'

function BulkCreateSection() {
  const address = 'https://feedbackclub.app/addrequestwizard/bulk';
  return (
    <FullPageSection>
      <div className="std-text bulk-section-description">Now you can use feedback club to improve your UX designs by getting feedback for your react applications running on offline systems.</div>
      <div className="std-text bulk-section-description">Download our react component 'feedback-provider' from npm and embed in your project.</div>
      <div className="std-text bulk-section-description">Login and create multiple feedback requests at once using this <a href={address} className="std-text">link</a>. 'feedback-provider.json' file will be created automatically. Place it to your project's public/feedback-provider folder.</div>
      <div className="std-text bulk-section-description">On your feedback requests page click on a bulk created request to see results for each request.</div>
    </FullPageSection>
  );
}

export default BulkCreateSection;
