import './ShareButtons.css';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";

const ShareButtons = (props) => {
    const { shareUrl, title, body } = props;

    return (
        <div className="share-button-container">
            <WhatsappShareButton
                url={shareUrl}>
                <WhatsappIcon
                    size={48}
                    round />
            </WhatsappShareButton>
            <TwitterShareButton
                url={shareUrl}
                title={title + ': '}
                hashtags={["feedbackclub"]}>
                <TwitterIcon
                    size={48}
                    round />
            </TwitterShareButton>
            <LinkedinShareButton
                url={shareUrl}
                title={title + ': '} //not working
                summary={body} //not working
                source="feedbackclub.app" //not working
            >
                <LinkedinIcon
                    size={48}
                    round />
            </LinkedinShareButton>
            <FacebookShareButton
                url={shareUrl}
                //quote={title} not working
                hashtag={["#feedbackclub"]}>
                <FacebookIcon
                    size={48}
                    round />
            </FacebookShareButton>
            <EmailShareButton
                url={shareUrl}
                subject={title}
                body={body}>
                <EmailIcon
                    size={48}
                    round />
            </EmailShareButton>
        </div >
    );
}
export default ShareButtons;