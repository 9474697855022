import useHttp from '../../hooks/use-http';
import FeedBackForm from '../FeedBackForm/FeedBackForm';
import Button from '../UI/Button/Button';
import Logo from '../UI/Logo/Logo';
import './GiveFeedBackPage.scss';
import Colors from '../../App/App.scss';
import { Fragment, useEffect, useState } from 'react';
import Spinner from '../UI/Spinner/Spinner';
import ErrorParagraph from '../UI/ErrorParagraph/ErrorParagraph';
import { useNavigate, useParams } from 'react-router-dom';
import { LINKS_INTERNAL } from '../../App/App';

const GiveFeedBackPage = () => {
    const [feedbackRequest, setFeedbackRequest] = useState([]);
    const [isExpired, setIsExpired] = useState();
    const [isRenderSuccessfullySubmitted, setIsRenderSuccessfullySubmitted] = useState(false);
    const [userIdentity, setUserIdentity] = useState();
    //normally we will get reference key from react router dynamic routes:
    const pathParams = useParams();
    const requesting_uid = pathParams.uid;
    const sequenceId = pathParams.sequenceId;
    const referenceKey = pathParams.requestId;
    const { isLoading, error, sendRequest } = useHttp();
    let subjectScores = {};
    const reportMailLink = `feedbackclub.app/givefeedback/${requesting_uid}/${referenceKey}`;
    const navigate = useNavigate();

    useEffect(() => {
        let userIdentity = localStorage.getItem('fbcui'); //feedbackclub user identifier
        if (!userIdentity) {
            userIdentity = crypto.randomUUID();
            localStorage.setItem('fbcui', userIdentity);
        }
        setUserIdentity(userIdentity);
    }, []);

    useEffect(() => {
        const transformFeedbackRequest = (data) => {

            if (Object.keys(data).length === 0) {
                navigate(LINKS_INTERNAL.MAIN_PAGE, { replace: false })
                return true;
            }

            let feedbackRequest;
            const singleKey = Object.keys(data)[0];
            feedbackRequest = data[singleKey];
            if (sequenceId) {
                feedbackRequest = feedbackRequest.relatedBulkRequestList[sequenceId];
            }

            const now = new Date();
            let isExpired = false;
            if (now >= new Date(feedbackRequest.expireDate)) {
                isExpired = true;
            }
            setIsExpired(isExpired);
            setFeedbackRequest(feedbackRequest);
        }

        const url = `https://feedback-club-v1-default-rtdb.europe-west1.firebasedatabase.app/feedbackRequest/${requesting_uid}.json?` + new URLSearchParams({
            orderBy: `"$key"`,
            equalTo: `"${referenceKey}"`,
        }).toString();

        sendRequest(
            {
                url: url
            },
            transformFeedbackRequest
        );
    }, [sendRequest, referenceKey, requesting_uid, navigate]);

    const onSubmitHandler = () => {
        const transformedSubjectScores = {};

        for (const key in subjectScores) {
            transformedSubjectScores[`${key}/${userIdentity}`] = subjectScores[key];
        }

        sendRequest(
            {
                url: `https://feedback-club-v1-default-rtdb.europe-west1.firebasedatabase.app/subjectScores/${requesting_uid}.json`,
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: transformedSubjectScores,
            },
            renderSuccess
        );
    }

    const renderSuccess = (data) => {
        setIsRenderSuccessfullySubmitted(true);
        return true;
    }

    const onSubjectValuesChangedHandler = (subjectScoresObj) => {
        subjectScores = subjectScoresObj;
    }

    return (
        <div className='give-feedback-page-container'>
            {!isRenderSuccessfullySubmitted && <Logo fontSize={50} color={Colors.lavenderGray} />}
            {isLoading && !isRenderSuccessfullySubmitted && <Spinner />}
            {error && <ErrorParagraph>{error}</ErrorParagraph>}
            {!isLoading && !error && isExpired &&
                <div className='expired-text std-text'>This feedback request has expired.</div>}
            {!isLoading && !error && !isExpired && !isRenderSuccessfullySubmitted &&
                <Fragment>
                    <div className='disclaimer-container'>
                        <div className='disclaimer std-text'>This is a feedback request page brought to you by Feedback Club application. All contents in the feedback request fields were created by the owner of the feedback request. If the page contains malicious, offensive or inappropriate content, please report it by sending an e-mail to feedbackclub.app@gmail.com or by clicking <a href={`mailto:feedbackclub.app@gmail.com?subject=Feedbackclub.app%20inappropriate%20content&body=Please%20don't%20delete%20the%20request%20link:%20${reportMailLink}`}>here</a>.</div>
                    </div>
                    <div className='give-feedback-form-container'>
                        <FeedBackForm style={{ paddingTop: "30px" }}
                            topic={feedbackRequest.topic}
                            description={feedbackRequest.description}
                            subjects={feedbackRequest.subjects}
                            expireDate={feedbackRequest.expireDate}
                            isExpired={isExpired}
                            isReadOnly={false}
                            onValueChanged={onSubjectValuesChangedHandler} />
                    </div>
                    <Button btnType='primary-button give-feedback-submit' onClick={onSubmitHandler}>Submit</Button>
                </Fragment>
            }
            {
                isRenderSuccessfullySubmitted &&
                <Fragment>
                    <p className='neon-text thank-you-text'>Thank you!</p>
                    {/*<p className='std-text success-message'>Your feedback has successfully submitted.</p>*/}
                </Fragment>
            }
        </div >
    );
}
export default GiveFeedBackPage;