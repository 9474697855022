export default function downloadJsonAsFile(jsonData, fileName) {
    // Convert the JSON object to a JSON string
    const jsonString = JSON.stringify(jsonData, null, 2);

    // Create a Blob containing the JSON data
    const blob = new Blob([jsonString], { type: 'application/json' });

    // Create a download link
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);

    // Set the download attribute and file name
    link.download = fileName || 'download.json';

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
}