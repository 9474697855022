import RequestList from "./RequestList";
import RequestListControls from "./RequestListControls";
import './FeedBackRequests.scss';
import PageFooter from "../UI/PageFooter/PageFooter";
import PageHeader from "../UI/PageHeader/PageHeader";
import { useContext, useEffect, useState } from "react";
import Modal from "../UI/Modal/Modal";
import ShareRequestPage from "../ShareRequestPage/ShareRequestPage";
import { useSelector } from 'react-redux';
import FeedBackForm from '../FeedBackForm/FeedBackForm';
import useHttp from "../../hooks/use-http";
import expireDateHelper from "../../helper/ExpireDateHelper";
import Spinner from "../UI/Spinner/Spinner";
import ErrorParagraph from "../UI/ErrorParagraph/ErrorParagraph";

//const testData = new TestRequests();

const feedBackRequests = [];//testData.sortingTestData;
let isFiltering = false;
let isSearching = false;
function FeedBackRequests() {
    const [requestList, setRequestList] = useState(feedBackRequests);
    const [fullRequestList, setFullRequestList] = useState(feedBackRequests);
    const [sortingOption, setSortingOption] = useState('Expire Date (Increasing)');
    const [isBeforeDataFetched, setIsBeforeDataFetched] = useState(true);
    const isShowRequestDetail = useSelector((state) => state.modal.isShowRequestDetail);
    const isShowShareRequestPage = useSelector((state) => state.modal.isShowShareRequestPage);
    const itemIdToShowDetailOrQr = useSelector((state) => state.modal.itemIdToShowDetailOrQr);
    const isRefreshList = useSelector((state) => state.modal.isRefreshList);
    const token = useSelector((state) => state.auth.token);
    const uid = useSelector((state) => state.auth.uid);
    const { isLoading, error, sendRequest: fetchData, } = useHttp();

    const convertFeedbackRequests = (receivedData) => {
        const feedBackRequestList = [];
        for (const key in receivedData) {
            const feedbackRequestItem = {};
            const receivedItem = receivedData[key];
            feedbackRequestItem.id = key;
            feedbackRequestItem.requestId = receivedItem.requestId || key;
            feedbackRequestItem.isChildRequest = receivedItem.isChildRequest;
            feedbackRequestItem.bulkrequestCount = receivedItem.bulkRequestCount;
            feedbackRequestItem.relatedBulkRequestList = receivedItem.relatedBulkRequestList;
            feedbackRequestItem.name = receivedItem.topic;
            feedbackRequestItem.description = receivedItem.description;
            feedbackRequestItem.subjects = receivedItem.subjects;
            feedbackRequestItem.expireDate = new Date(receivedItem.expireDate);
            const { isExpired, remainingTime, dayHourStr } = expireDateHelper(feedbackRequestItem.expireDate);
            feedbackRequestItem.isExpired = isExpired;
            feedbackRequestItem.remainingTime = remainingTime;
            feedbackRequestItem.dayHourStr = dayHourStr;
            feedbackRequestItem.fBCount = 0; // bu requeste ait subjectlerin en çok oy verilmiş olanının oy sayısı.
            feedbackRequestItem.avgScore = 0; // bu requeste ait subjectlerin scorelarının ortalaması. 
            // DB'de relatedBulkRequestList'in elemanlarının key'leri 0..N şeklinde index bilgisi olduğundan QR ve link oluşturuken request id bilgisi bu index olmaktadır. 
            // request id'ler veri yaratılırken DB'de otomatik oluştuğundan relatedBulkRequestList içinde bu id'yi yaratırken kullanmak mümkün görünmüyor.
            // Bu durumu düzeltmek için veriyi okurken parentin key'ini child nesneye requestId olarak setlemek ve link oluşturuken key yerine requestId verisini kullanmak gerekmektedir.
            if (feedbackRequestItem.relatedBulkRequestList) {
                feedbackRequestItem.relatedBulkRequestList.forEach(relatedBulkRequest => relatedBulkRequest.requestId = key);
            }
            feedBackRequestList.push(feedbackRequestItem);
        }
        if (feedBackRequestList && feedBackRequestList.length > 0) {
            const promisses = fetchSubjectScoreResults(feedBackRequestList);
            Promise.all(promisses).then(results => processAllScoreResults(results, feedBackRequestList)).catch(error => {
                // Handle errors from any of the fetch calls
                console.error('Error fetching data:', error);
            });
        } else {
            setFullRequestList(feedBackRequestList);
            setRequestList(feedBackRequestList);
            setIsBeforeDataFetched(false);
        }
    };

    const fetchSubjectScoreResults = (feedBackRequestList) => {
        const promisses = [];
        for (const fbr of feedBackRequestList) {
            if (fbr.relatedBulkRequestList) {
                promisses.push(...fetchSubjectScoreResults(fbr.relatedBulkRequestList));
            }
            promisses.push(...fetchAllSubjectsOfaRequest(fbr));
        }
        return promisses;
    };

    const fetchAllSubjectsOfaRequest = (request) => {
        const promisses = [];
        for (const subject of request.subjects) {
            promisses.push(fetch(
                `https://feedback-club-v1-default-rtdb.europe-west1.firebasedatabase.app/subjectScores/${uid}.json?` + new URLSearchParams({
                    orderBy: '"$key"',
                    equalTo: `"${subject.id}"`,
                    auth: `${token}`
                }), {
                method: 'GET'
            }
            ).then(response => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok: ${response.statusText}`);
                }
                return response.json();
            }));
        }
        return promisses;
    }

    const processAllScoreResults = (allSubjectScoresData, feedBackRequestList) => {
        const simpleResult = simplifyResult(allSubjectScoresData);
        calculateAndSetAllStatisticsData(simpleResult, feedBackRequestList);
        setFullRequestList(feedBackRequestList);
        setRequestList(feedBackRequestList);
        setIsBeforeDataFetched(false);
    }

    const calculateAndSetAllStatisticsData = (simpleResult, feedBackRequestList) => {
        for (const fbr of feedBackRequestList) {
            const userIdentitySet = new Set(); // her request için ilklendiriliyor.
            setSubjectStatistics(fbr, userIdentitySet, simpleResult);
            setRequestStatistics(fbr, userIdentitySet);
        }
    }

    const setSubjectStatistics = (fbr, userIdentitySet, simpleResult) => {
        for (const subject of fbr.subjects) {
            setSubjectStatisticsForSingleRequest(subject, simpleResult[subject.id], userIdentitySet);
        }
        if (fbr.relatedBulkRequestList) {
            fbr.relatedBulkRequestList.forEach(relatedFbr => setSubjectStatistics(relatedFbr, userIdentitySet, simpleResult));
            setAvgSubjectStatisticsForBulkRequest(fbr);
        }
    }

    // Todo: fBCount unique user count mantığıyla çalışmalı. 
    const setAvgSubjectStatisticsForBulkRequest = (fbr) => {
        for (let subject of fbr.subjects) {
            const relatedSubjects = [];
            fbr.relatedBulkRequestList.forEach(relatedReq => {
                const filteredSbj = relatedReq.subjects.filter(sbj => sbj.parentSubjectId === subject.id && sbj.avgScore);
                relatedSubjects.push(...filteredSbj);
            });
            Object.assign(
                subject, relatedSubjects.reduce((prev, curr) => {
                    return { fBCount: Math.max(prev.fBCount, curr.fBCount), avgScore: ((prev.avgScore * prev.fBCount + curr.avgScore * curr.fBCount) / (curr.fBCount + prev.fBCount)).toFixed(1) };
                }, { fBCount: 0, avgScore: 0 })
            );
            if (!subject.fBCount) subject.fBCount = 0;
            if (!subject.avgScore) subject.avgScore = 0;
        }
    }

    const simplifyResult = (allSubjectScoresData) => {
        const simplyObject = {}
        allSubjectScoresData.forEach(subjectScore => Object.assign(simplyObject, subjectScore));
        return simplyObject;
    }

    const setRequestStatistics = (feedBackrequestToManipulate, userIdentitySet) => {
        if (feedBackrequestToManipulate.relatedBulkRequestList) {
            setRequestStatisticsForBulkRequest(feedBackrequestToManipulate, userIdentitySet)
        } else {
            setRequestStatisticsForSingleRequest(feedBackrequestToManipulate, userIdentitySet)
        }
    }

    const setRequestStatisticsForSingleRequest = (feedBackrequestToManipulate, userIdentitySet) => {
        if (!feedBackrequestToManipulate.subjects) return;
        const result = feedBackrequestToManipulate.subjects
            .filter(s => s.avgScore && s.fBCount)
            .map(sub => {
                const avgScore = sub.avgScore;
                const fBCount = sub.fBCount;
                return { avgScore, fBCount };
            })
            .reduce((prev, curr) => {
                return { fBCount: prev.fBCount + curr.fBCount, avgScore: (prev.avgScore * prev.fBCount + curr.avgScore * curr.fBCount) / (curr.fBCount + prev.fBCount) };
            }, { fBCount: 0, avgScore: 0 });

        feedBackrequestToManipulate.avgScore = parseFloat(result.avgScore).toFixed(1);

        feedBackrequestToManipulate.fBCount = userIdentitySet.size;
    }

    const setRequestStatisticsForBulkRequest = (feedBackrequestToManipulate, userIdentitySet) => {
        if (!feedBackrequestToManipulate.relatedBulkRequestList) return;
        const allSubjectsList = [];
        feedBackrequestToManipulate.relatedBulkRequestList.forEach(req => allSubjectsList.push(...req.subjects));
        const result = allSubjectsList
            .filter(s => s.avgScore && s.fBCount)
            .map(sub => {
                const avgScore = sub.avgScore;
                const fBCount = sub.fBCount;
                return { avgScore, fBCount };
            })
            .reduce((prev, curr) => {
                return { fBCount: prev.fBCount + curr.fBCount, avgScore: (prev.avgScore * prev.fBCount + curr.avgScore * curr.fBCount) / (curr.fBCount + prev.fBCount) };
            }, { fBCount: 0, avgScore: 0 });

        feedBackrequestToManipulate.avgScore = parseFloat(result.avgScore).toFixed(1);

        feedBackrequestToManipulate.fBCount = userIdentitySet.size;
    }

    const setSubjectStatisticsForSingleRequest = (subjectToManipulate, data, userIdentitySet) => {
        if (!data) return;
        const subjectFeedBackCount = Object.keys(data).length;
        const subjectFeedbackScoreList = Object.values(data);
        const sumOfScoresOfASubject = subjectFeedbackScoreList.reduce((acc, cur) => acc + cur);
        const averageScoreForASubject = (sumOfScoresOfASubject / subjectFeedBackCount).toFixed(1);
        subjectToManipulate.avgScore = averageScoreForASubject;
        subjectToManipulate.fBCount = subjectFeedBackCount;
        subjectToManipulate.subjectScoreList = subjectFeedbackScoreList;
        Object.keys(data).forEach(k => userIdentitySet.add(k));
    }

    useEffect(() => {
        fetchData({
            url: `https://feedback-club-v1-default-rtdb.europe-west1.firebasedatabase.app/feedbackRequest/${uid}.json?auth=${token}`
        }, convertFeedbackRequests);
    }, [isRefreshList]);

    const filter = (filter) => {
        const now = new Date();
        isFiltering = true;
        isSearching = false;
        if (filter === 'Active') {
            setRequestList(
                sortList(sortingOption, fullRequestList.filter(item => item.expireDate > now))
            );
        } else if (filter === 'Expired') {
            setRequestList(
                sortList(sortingOption, fullRequestList.filter(item => item.expireDate < now))
            );
        } else {
            setRequestList(sortList(sortingOption, fullRequestList));
        }
    }

    const sort = (sort) => {
        setSortingOption(sort);
        setRequestList(arr => sortList(sort, [...arr]));
    }

    const sortList = (sortType, arr) => {
        if (sortType === 'Expire Date (Increasing)') {
            arr.sort((a, b) => a.expireDate > b.expireDate ? 1 : -1);
        } else if (sortType === 'Expire Date (Decreasing)') {
            arr.sort((a, b) => a.expireDate < b.expireDate ? 1 : -1);
        } else if (sortType === 'Max Count') {
            arr.sort((a, b) => a.fBCount < b.fBCount ? 1 : -1);
        } else if (sortType === 'Min Count') {
            arr.sort((a, b) => a.fBCount > b.fBCount ? 1 : -1);
        } else if (sortType === 'Max Score') {
            arr.sort((a, b) => a.avgScore < b.avgScore ? 1 : -1);
        } else if (sortType === 'Min Score') {
            arr.sort((a, b) => a.avgScore > b.avgScore ? 1 : -1);
        }
        return arr;
    }

    const search = (searchKey) => {
        isSearching = true;
        isFiltering = false;
        setRequestList(sortList(sortingOption, [...fullRequestList]).filter(a => a.name.includes(searchKey)));
    }

    const hasResult = requestList.length > 0;

    //if isShowRequestDetail is true then find the item to show details for.
    let itemToShowDetails;
    if (isShowRequestDetail) {
        itemToShowDetails = requestList.find(r => r.id === itemIdToShowDetailOrQr);
    }

    let pagePayload;
    if (isLoading) {
        pagePayload = <Spinner />;
    } else if (error) {
        pagePayload = <ErrorParagraph>{error}</ErrorParagraph>;
    } else if (isBeforeDataFetched) {
        pagePayload = <Spinner />;
    } else {
        pagePayload = hasResult ? <RequestList listItems={requestList} setRequestList={convertFeedbackRequests} /> : <h2 className="no-results std-text">No results found.</h2>;
    }
    return (
        <div id="feed-back-requests">
            <div className="fixed-page-top">
                <PageHeader />
                <RequestListControls filter={filter} sort={sort} search={search} isFiltering={isFiltering} isSearching={isSearching} />
            </div>
            <div className={`payload-container ${(isShowRequestDetail || isShowShareRequestPage) && 'scroll-with-modal-fix'}`}>
                {pagePayload}
            </div>
            <PageFooter />
            <Modal>
                {isShowRequestDetail && <FeedBackForm
                    topic={itemToShowDetails.name}
                    description={itemToShowDetails.description}
                    subjects={itemToShowDetails.subjects}
                    expireDate={itemToShowDetails.expireDate}
                    isExpired={itemToShowDetails.isExpired}
                    isReadOnly={true}
                    bulkrequestCount={itemToShowDetails.bulkrequestCount} />}
                {isShowShareRequestPage && <ShareRequestPage requestReferenceKey={itemIdToShowDetailOrQr} uid={uid} />}
            </Modal>
        </div >
    );
}

export default FeedBackRequests;