import VotingButtons from "../UI/VotingButtons/VotingButtons";

const SubjectListInput = (props) => {

    const subjectValueList = {};

    const onValueChanged = (id, score) => {
        subjectValueList[id] = score;
        props.onValueChanged(subjectValueList);
    }

    const subjectsRendered = props.subjects ? props.subjects.map((subject, index) =>
        <div key={subject.id}>
            <div className="feedbackform-subject-name std-text">{index + 1}. {subject.subject}</div>
            <VotingButtons onValueChanged={onValueChanged.bind(null, subject.id)} />
        </div>
    ) : null;
    return subjectsRendered;
}
export default SubjectListInput;