import useValidation from "../../hooks/use-validation";
import ValidationErrorBox from "../UI/ValidationErrorBox/ValidationErrorBox";
import WisardLayout from "./WisardLayout";
import './AddRequestWisardBulkCreate.scss';
import AddRequestContext from "./add-request-context";
import { useContext, useEffect } from "react";

const AddRequestWisardBulkCreate = () => {
    const ctx = useContext(AddRequestContext);
    const {
        onBlurHandler: requestCountBlurHandler,
        onChangeHandler: requestCountChangeHandler,
        value: requestCountValue,
        isInputValid: isRequestCountValid,
        hasErrors: requestCountHasErrors,
        onFocusHandler: requestCountFocusHandler,
        showErrorBox: requestCountShowErrorBox,
        forceError: requestCountForceError,
        setValue: setRequestCountValue
    } = useValidation((val) => {
        return val > 0 && val < 1001;
    });

    //loading input values from context if saved earlier (e.g. if his page is loaded with back button)
    useEffect(() => {
        if (ctx.requestCount) {
            setRequestCountValue(ctx.requestCount);
        }
    }, [ctx.requestCount, setRequestCountValue]);

    const forceValidate = () => {
        if (!isRequestCountValid) {
            requestCountForceError();
        }
        return isRequestCountValid;
    }

    const desc = 'How many QR codes do you want for this feedback request? Each request will have the same content but you will be able to get feedback seperately.';
    const validErrMes = 'Please enter a number between 1-50';
    const saveState = () => {
        ctx.setRequestCount(requestCountValue);
    }

    return (
        <WisardLayout context={AddRequestContext} description={desc} nextButtonName='Continue' backButtonName='Back' forceValidate={forceValidate} saveState={saveState}>
            <ValidationErrorBox message={validErrMes} showErrorBox={requestCountShowErrorBox}>
                <input className={`text-input description-input ${requestCountHasErrors && 'invalid'}`} min="1" max="50" type="number" placeholder="Count" onFocus={requestCountFocusHandler} onBlur={requestCountBlurHandler} onChange={requestCountChangeHandler} value={requestCountValue} autoFocus />
            </ValidationErrorBox>
        </WisardLayout>
    );
}

export default AddRequestWisardBulkCreate;