import { Fragment } from 'react';
import './RemainingTime.scss';

const RemainingTime = (props) => {
    return <div className={`std-datetime-box remaining-time ${props.expired && "expired"} `}>
        {props.expired ? 'Expired' :
            <Fragment>
                <div className="std-text" style={{ fontSize: 'medium' }}>Last</div>
                <div className="std-text" style={{ fontSize: 'x-large' }}>{props.remainingTime}</div>
                <div className="std-text" style={{ fontSize: 'large' }}>{props.dayHourStr}</div>
            </Fragment>
        }
    </div>
}

export default RemainingTime;