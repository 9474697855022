import { createSlice } from '@reduxjs/toolkit';

let storedToken = localStorage.getItem('fbc_token');
let storedUid = localStorage.getItem('fbc_uid');

const initialAuthState = {
    token: storedToken ? storedToken : '',
    isLoggedIn: storedToken ? true : false,
    uid: storedUid ? storedUid : null
};

const authSlice = createSlice({
    name: 'authModal',
    initialState: initialAuthState,
    reducers: {
        login(state, action) {
            localStorage.setItem('fbc_token', action.payload.token);
            localStorage.setItem('fbc_uid', action.payload.uid);
            localStorage.setItem('fbc_expirationTime', action.payload.expirationTime);
            state.token = action.payload.token;
            state.uid = action.payload.uid;
            state.isLoggedIn = true;
        },
        logout(state) {
            state.token = null;
            state.uid = null;
            state.isLoggedIn = false;
            localStorage.removeItem('fbc_token');
            localStorage.removeItem('fbc_uid');
            localStorage.removeItem('fbc_expirationTime');
        },
    },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;