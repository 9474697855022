import PageHeader from '../UI/PageHeader/PageHeader';
import PageFooter from '../UI/PageFooter/PageFooter';
import Button from "../UI/Button/Button";
import './WisardLayout.scss';
import { useContext, useState } from 'react';
import Link from '../UI/Link/Link';
import Colors from '../../App/App.scss';

const WisardLayout = (props) => {
    const { context, forceValidate, saveState, description, moreDescription, backButtonName, nextButtonName, hideCancelButton, isShowButtons = true, isMoveForwardDisabled = false, isSaveOnBackDisabled = false } = props;
    const [isShowMore, setIsShowMore] = useState(false);
    const ctx = useContext(context);
    const onBackClickHandler = () => {
        if (saveState && !isSaveOnBackDisabled) {
            saveState();
        }
        ctx.moveBackward();
    }

    const onNextClickHandler = () => {
        if (forceValidate()) {
            if (saveState) {
                saveState();
            }
            if (!isMoveForwardDisabled) {
                ctx.moveForward();
            }
        }
    }

    const onCancelClickHandler = () => {
        ctx.cancel();
    }

    const showMoreClickedHandler = () => {
        setIsShowMore(true);
    }

    const showLessClickedHandler = () => {
        setIsShowMore(false);
    }

    return (
        <div className='wisard-layout'>
            <PageHeader className='wisard-page-header' />
            <div className='description-container'>
                <div className='wisard-description std-text'>
                    {description}
                    {!isShowMore && moreDescription && <Link linkClicked={showMoreClickedHandler} style={{ color: Colors.ceruleanCrayola }}>show more</Link>}
                    {isShowMore && <div className='wisard-description std-text'>{moreDescription}</div>}
                    {isShowMore && <Link linkClicked={showLessClickedHandler} style={{ color: Colors.ceruleanCrayola }}>show less</Link>}
                </div>
            </div>
            {props.children}
            {isShowButtons && <div className='wisard-buttons'>
                <Button btnType='secondary-button back-button' onClick={onBackClickHandler}>{backButtonName}</Button>
                <Button btnType='secondary-button cancel-button' onClick={onCancelClickHandler} disabled={hideCancelButton}>Cancel</Button>
                <Button btnType='primary-button next-button' onClick={onNextClickHandler}>{nextButtonName}</Button>
            </div>}
            <PageFooter />
        </div>
    );
}
export default WisardLayout;