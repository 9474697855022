import './FullPageSection.scss'

function FullPageSection(props) {
  return (
      <section id="full-page-section" style={props.style} className={props.className}>
        {props.children}
      </section>
  );
}

export default FullPageSection;