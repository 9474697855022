import AddRequestContext from "./add-request-context";
import WisardLayout from "./WisardLayout";

const AddRequestWisardIntro = () => {

    const desc = 'This wizard will guide you in creating a new feedback request in 4 simple steps. Click Start button when you are ready!';

    return (
        <WisardLayout context={AddRequestContext} description={desc} nextButtonName='Start' backButtonName='Cancel' forceValidate={() => true} hideCancelButton={true} />
    );
}

export default AddRequestWisardIntro;