import WisardLayout from "./WisardLayout";
import './AddRequestWisardSubjects.scss';
import { useContext, useEffect, useState } from "react";
import DynamicList from "../UI/DynamicList/DynamicList";
import Button from "../UI/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AddRequestContext from "./add-request-context";

const AddRequestWisardSubjects = () => {
    const initialState = [{ id: crypto.randomUUID(), subject: "" }];
    const [itemList, setItemList] = useState(initialState);
    const [isForceValidate, setIsForceValidate] = useState(false);
    const desc = 'Please type the subjects of your feedback request.';
    const moreDesc = `\n Subjects are the subheadings for which you request feedback. As an example you can request feedback for the "Scope of content", "Narrative skills", "Voice level", "Outlook", "Effective use of time" for your presentation.`;
    const validateFunction = val => {
        const len = val.trim().length;
        return len >= 3 && len < 512;
    };
    const validationErrorMessage = "Please enter a feedback subject between 3-512 characters long.";
    const placeholder = "Feedback subject";

    const ctx = useContext(AddRequestContext);
    const { subjects: ctxSubjects } = ctx;
    //loading input values from context if saved earlier (e.g. if his page is loaded with back button)
    useEffect(() => {
        if (ctxSubjects) {
            setItemList(ctxSubjects);
        }
    }, [ctxSubjects, setItemList]);

    const isFormValid = () => {
        return !(itemList[itemList.length - 1].subject === '');
    }

    const forceValidate = () => {
        const isValid = isFormValid();
        if (!isValid) {
            setIsForceValidate(true);
        } else {
            setIsForceValidate(false);
        }
        return isValid;
    }

    const btnClickHandler = () => {
        if (forceValidate()) {
            setItemList((itemList) => {
                return itemList.concat({ id: crypto.randomUUID(), subject: "" })
            });
        }
    }

    const saveState = () => {
        ctx.setSubjects(itemList);
    }

    const updateItemList = (inputValue, id) => {
        const itemListCopy = itemList.slice();
        itemListCopy.forEach(i => {
            if (i.id === id) {
                i.subject = inputValue;
            }
        }
        );
        setItemList(itemListCopy);
    }

    const removeItemHandler = (id) => {
        if (itemList.length > 1) {
            setItemList(itemList => itemList.filter(i => i.id !== id));
        }
    }

    return (
        <WisardLayout context={AddRequestContext} description={desc} moreDescription={moreDesc} nextButtonName='Continue' backButtonName='Back' forceValidate={forceValidate} saveState={saveState}>
            <DynamicList onRemoveItem={removeItemHandler} itemList={itemList.map(i => { return { id: i.id, initialValue: i.subject } })} updateItemList={updateItemList} validateFunction={validateFunction} validationErrorMessage={validationErrorMessage} placeholder={placeholder} forceValidate={isForceValidate} inputClassName='subjects-input' />
            <Button btnType="tertiary-button add-new-button" onClick={btnClickHandler}><FontAwesomeIcon icon={faPlus} size='3x' color={'white'} /></Button>
        </WisardLayout>
    );
}

export default AddRequestWisardSubjects;