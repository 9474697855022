import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../store/auth-slice";

let logoutTimer;

const useAutoLogoutBeforeExpire = () => {
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const dispatch = useDispatch();

    const calculateRemainingTime = (expirationTime) => {
        const currentTime = new Date().getTime();
        const adjExpirationTime = new Date(expirationTime).getTime();
        const remainingDuration = adjExpirationTime - currentTime;
        return remainingDuration;
    };

    let storedExpirationDate = localStorage.getItem('fbc_expirationTime');

    let remainingTime = storedExpirationDate ? calculateRemainingTime(storedExpirationDate) : null;
    const logoutHandler = useCallback(() => {
        dispatch(authActions.logout());
    }, [dispatch]);

    if (remainingTime && remainingTime <= 3600) {
        localStorage.removeItem('fbc_token');
        localStorage.removeItem('fbc_expirationTime');
        localStorage.removeItem('fbc_uid');
        remainingTime = null;
        //call logout because auth-slice may already taken token from localstorage and app state may be evaluated as login.
        dispatch(authActions.logout());
    }

    useEffect(() => {
        if (remainingTime) {
            logoutTimer = setTimeout(logoutHandler, remainingTime);
        }
    }, [remainingTime, logoutHandler]);

    useEffect(() => {
        if (isLoggedIn) {
            logoutTimer = setTimeout(logoutHandler, remainingTime);
        } else {
            if (logoutTimer) {
                clearTimeout(logoutTimer);
            }
        }
    }, [isLoggedIn, logoutHandler, remainingTime]);
}

export default useAutoLogoutBeforeExpire;