import React from "react";

const initialState = {
    topic: '',
    description: '',
    subjects: [],
    expireDate: null,
    requestCount: null,
    referenceKey: null,
    setTopic: null,
    setDescription: null,
    setSubjects: null,
    setExpireDate: null,
    setRequestCount: null,
    setReferenceKey: null,
    moveForward: null,
    moveBackward: null,
    cancel: null
};
const AddRequestContext = React.createContext(initialState);
export default AddRequestContext;