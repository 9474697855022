import QRCode from 'qrcode.react';
import './QrCode.scss';

const QrCode = (props) => {
    const link = props.link;
    const download = () => {
        const canvas = document.getElementById("qr-gen");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `feedbackclub.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
    return <QRCode id='qr-gen' className='generated-qr-code' value={link} size={230} onClick={download} />;
}

export default QrCode;