import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../Button/Button";
import './VotingButton.scss';

const VotingButton = (props) => {
    const onMouseEnterHandler = () => {
        props.onMouseEntered(props.id);
    }

    const onMouseLeaveHandler = () => {
        props.onMouseLeaved();
    }

    const onClickHandler = () => {
        props.onClick(props.id);
    }


    return (
        <Button btnType='tertiary-button voting-button' buttonProps={{ onMouseEnter: onMouseEnterHandler, onMouseLeave: onMouseLeaveHandler, onClick: onClickHandler }}><FontAwesomeIcon icon={faStar} size='2x' color={props.color} /></Button>
    );
}
export default VotingButton;