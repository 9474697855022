import ExpireDate from '../UI/ExpireDate/ExpireDate';
import './RequestItem.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from '../../App/App.scss';
import Button from '../UI/Button/Button';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../../store/show-modal-slice';
import NumericResultsDisplay from './NumericResultsDisplay';
import useHttp from '../../hooks/use-http';
import Spinner from '../UI/Spinner/Spinner';

function RequestItem(props) {
    const dispatch = useDispatch();
    const { error, isLoading, sendRequest } = useHttp();
    const uid = useSelector(state => state.auth.uid);
    const token = useSelector(state => state.auth.token);
    const { setRequestList } = props;
    const { bulkrequestCount, relatedBulkRequestList } = props.item;

    const qrOnClickHandler = (e) => {
        e.stopPropagation();
        let itemIdToShowDetailOrQr = null;
        if (props.item.isChildRequest) {
            itemIdToShowDetailOrQr = `${props.item.requestId}/${props.item.id}`;
        } else {
            itemIdToShowDetailOrQr = `${props.item.requestId}`;
        }
        dispatch(modalActions.showShareRequest(itemIdToShowDetailOrQr));
    }

    const listBulkrequests = (e) => {
        e.stopPropagation();
        setRequestList(relatedBulkRequestList);
    }

    const refreshAllList = () => {
        dispatch(modalActions.refreshList());
    }

    const trashOnClickHandler = (e) => {
        e.stopPropagation();
        cleanAllSubjectsDataRelatedToRequest(props.item);
        sendRequest({
            url: `https://feedback-club-v1-default-rtdb.europe-west1.firebasedatabase.app/feedbackRequest/${uid}/${props.item.id}.json?auth=${token}`,
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        }, refreshAllList);
    }

    //Todo: BUrada çok fazla sil isteği atılıyor. SubjectScores tablosunda subject'leri request ID altında toplarsan requesti silmek yeterli olacaktır.
    const cleanAllSubjectsDataRelatedToRequest = (requestItem) => {
        deleteSubjectsOfaRequest(requestItem);
        if (requestItem.relatedBulkRequestList) {
            for (const request of requestItem.relatedBulkRequestList) {
                deleteSubjectsOfaRequest(request);
            }
        }
    }

    const deleteSubjectsOfaRequest = (requestItem) => {
        if (!requestItem.subjects) {
            return;
        }
        for (const sbj of requestItem.subjects) {
            sendRequest({
                url: `https://feedback-club-v1-default-rtdb.europe-west1.firebasedatabase.app/subjectScores/${uid}/${sbj.id}.json?auth=${token}`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            }, () => { });
        }
    }

    const itemClickHandler = () => {
        //if (bulkrequestCount) return;
        dispatch(modalActions.showRequestDetail(props.item.id));
    }

    return (
        <li className='request-item card' onClick={itemClickHandler}>
            <div id='date-and-name'>
                <ExpireDate expireDate={props.item.expireDate} expired={props.item.isExpired} remainingTime={props.item.remainingTime} dayHourStr={props.item.dayHourStr} />
                <div className='feed-back-name std-text' >{props.item.name}</div>
            </div>
            <div className='row-actions-container'>
                {!isLoading && !props.item.isChildRequest && <Button btnType='tertiary-button trash-button' onClick={trashOnClickHandler}><FontAwesomeIcon id='trash-icon' icon={faTrashCan} size='2x' color={Colors.errorRed} /></Button>}
                {isLoading && <Spinner margin="0px" color="white" className="trash-spinner" />}
                {!bulkrequestCount && <Button btnType='tertiary-button qr-button' onClick={qrOnClickHandler}><div id='qr-icon' /></Button>}
                {bulkrequestCount && <Button btnType='tertiary-button list-icon' onClick={listBulkrequests}><FontAwesomeIcon icon={faList} size='2x' color={Colors.beige} /></Button>}
                <NumericResultsDisplay fBCount={props.item.fBCount} avgScore={props.item.avgScore} />
            </div>
        </li >
    );
}
export default RequestItem;