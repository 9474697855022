import './CalenderDayView.scss';
const CalenderDayView = (props) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const date = props.date;
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    return (
        <div className={`std-datetime-box ${props.expired && "expired"} calender-view`}>
            <div className="std-text month">{month}</div>
            <div className="std-text day">{day}</div>
            <div className="std-text year">{year}</div>
        </div>
    );
}
export default CalenderDayView;