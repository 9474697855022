import './Button.scss'

function Button(props) {
    return (
        <button type={props.type} className={`std-btn std-text ${props.btnType}`} onClick={props.onClick} disabled={props.disabled} {...props.buttonProps} >
            {props.children}
        </button>
    )
}

Button.defaultProps = {
    type: 'button',
    btnType: 'secondary-button'
}

export default Button;