import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Button from '../UI/Button/Button'
import SelectionBox from '../UI/SelectionBox/SelectionBox';
import SearchBar from '../UI/SearchBar/SearchBar';
import './RequestListControls.scss';
import { LINKS_INTERNAL } from '../../App/App';
import { useNavigate } from 'react-router-dom';

function RequestListControls(props) {
    let navigate = useNavigate();
    const filterList = ['All', 'Active', 'Expired'];
    const sortList = ['Expire Date (Increasing)', 'Expire Date (Decreasing)', 'Max Count', 'Min Count', 'Max Score', 'Min Score']

    const btnClickHandler = () => {
        navigate(LINKS_INTERNAL.ADD_REQUEST_WISARD, { replace: false });
    }

    return <div className="list-controls">
        <SelectionBox values={filterList} className="filter-select" onChange={props.filter} reset={!props.isFiltering} />
        <SelectionBox values={sortList} className="sort-select" onChange={props.sort} />
        <SearchBar onSubmit={props.search} reset={!props.isSearching} />
        <Button btnType="tertiary-button add-new-button" onClick={btnClickHandler}><FontAwesomeIcon icon={faPlus} size='3x' color={'white'} /></Button>
    </div>;
}

export default RequestListControls;