import './ShareRequestPage.scss';
import LinkAndCopy from "../UI/LinkAndCopy/LinkAndCopy";
import QrCode from "../UI/QrCode/QrCode";
import ShareButtons from "../UI/ShareButtons/ShareButtons";

const ShareRequestPage = (props) => {
    const address = 'http://feedbackclub.app/givefeedback/';
    const fullAddress = `${address}${props.uid}/${props.requestReferenceKey}`;
    return (
        <div className='share-request-page-container'>
            <LinkAndCopy address={fullAddress} width={270} />
            <QrCode link={fullAddress} />
            <ShareButtons shareUrl={fullAddress} title="Feedback Club Feedback Request" body="Please click the link to open the feedback page generated by Feedback Club. Thank you for taking the time to help us get stronger." />
        </div>
    );
}
export default ShareRequestPage;