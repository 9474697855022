import { useState } from 'react';

const useValidation = (validate) => {
    const [value, setValue] = useState('');
    const [isTouched, setIsTouched] = useState(false);
    const [hasFocus, setHasFocus] = useState(false);

    const isInputValid = validate(value);
    const hasErrors = !isInputValid && isTouched;
    const showErrorBox = hasErrors && hasFocus;

    const onBlurHandler = () => {
        setIsTouched(true);
        setHasFocus(false);
    }

    const onChangeHandler = (event) => {
        setValue(event.target.value);
    }

    const resetInput = () => {
        setIsTouched(false);
        setValue('');
    }

    const forceError = () => {
        setIsTouched(true);
    }

    const onFocusHandler = () => {
        setHasFocus(true);
    }

    return {
        onBlurHandler: onBlurHandler,
        onChangeHandler: onChangeHandler,
        value: value,
        isInputValid: isInputValid,
        hasErrors: hasErrors,
        resetInput: resetInput,
        onFocusHandler: onFocusHandler,
        showErrorBox: showErrorBox,
        forceError: forceError,
        setValue
    };
};

export default useValidation;