import './DynamicList.scss';
import DynamicListItem from './DynamicListItem';

const DynamicList = (props) => {
    const { itemList, updateItemList, validateFunction, validationErrorMessage, placeholder, forceValidate } = props;
    let forceValidateItem = false;

    const valueChangedHandler = (inputValue, id) => {
        updateItemList(inputValue, id);
    }

    const displayItems = itemList.map((i, ind) => {

        if (ind === itemList.length - 1) {
            forceValidateItem = forceValidate;
        }
        return <DynamicListItem onRemoveItem={props.onRemoveItem} initialValue={i.initialValue} key={i.id} index={ind + 1} id={i.id} validateFunction={validateFunction} validationErrorMessage={validationErrorMessage} placeholder={placeholder} forceValidate={forceValidateItem} inputClassName={props.inputClassName} valueChangedHandler={valueChangedHandler} />
    });
    return (displayItems);
}
export default DynamicList;