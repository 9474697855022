import useValidation from "../../hooks/use-validation";
import ValidationErrorBox from "../UI/ValidationErrorBox/ValidationErrorBox";
import WisardLayout from "./WisardLayout";
import './AddRequestWisardDescription.scss';
import AddRequestContext from "./add-request-context";
import { useContext, useEffect } from "react";

const AddRequestWisardDescription = () => {
    const {
        onBlurHandler: descriptionBlurHandler,
        onChangeHandler: descriptionChangeHandler,
        value: descriptionValue,
        isInputValid: isDescriptionValid,
        hasErrors: descriptionHasErrors,
        onFocusHandler: descriptionFocusHandler,
        showErrorBox: descriptionShowErrorBox,
        forceError: descriptionForceError,
        setValue: setDescriptionValue
    } = useValidation((val) => {
        const len = val.trim().length;
        return len > 2 && len < 1024;
    });

    const ctx = useContext(AddRequestContext);

    //loading input values from context if saved earlier (e.g. if his page is loaded with back button)
    useEffect(() => {
        if (ctx.description) {
            setDescriptionValue(ctx.description);
        }
    }, [ctx.description, setDescriptionValue]);

    const forceValidate = () => {
        if (!isDescriptionValid) {
            descriptionForceError();
        }
        return isDescriptionValid;
    }

    const desc = 'Please type a description for your feedback request.';
    const validErrMes = 'Please enter a description between 3-1024 characters long';
    const saveState = () => {
        ctx.setDescription(descriptionValue);
    }

    return (
        <WisardLayout context={AddRequestContext} description={desc} nextButtonName='Continue' backButtonName='Back' forceValidate={forceValidate} saveState={saveState}>
            <ValidationErrorBox message={validErrMes} showErrorBox={descriptionShowErrorBox}>
                <input className={`text-input description-input ${descriptionHasErrors && 'invalid'}`} type="text" placeholder="feedback description" onFocus={descriptionFocusHandler} onBlur={descriptionBlurHandler} onChange={descriptionChangeHandler} value={descriptionValue} autoFocus />
            </ValidationErrorBox>
        </WisardLayout>
    );
}

export default AddRequestWisardDescription;