import './Link.scss'

function Link(props){
    return (
        <div className='std-link std-text' onClick={props.linkClicked} style={props.style}>
            {props.children}
        </div>
    );
}

export default Link;